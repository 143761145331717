<template>
    <error-content
      image-file-name="forbidden-robo"
    ></error-content>
</template>

<script>

import ErrorContent from '@/views/Fehlerseiten/Fehler.vue'

export default {
    name: 'Forbidden',
    components: { ErrorContent },
    metaInfo: {
        title: 'No access',
    },
}
</script>
